/** @jsx jsx */
import { Styled, jsx, Box, Flex } from 'theme-ui'
// import Img from 'gatsby-image'
import styled from '@emotion/styled'
import Button from './button'
import { Container } from './item'

import tintRollImg from '../images/tint-no-logo-compressor.jpg'

const StyledBox = styled(Box)`
  background-image: url(${tintRollImg});
  background-size: cover;
  background-position: center;

  @media screen and (max-width:900px) {
    background-position: right;
  }
`

const ShapedCard = ({title, text, headerColor, children, fluidImg, to, ...props}) => (
  <Container>
    <Flex
      {...props}
      sx={{
        width: '100%',
        maxWidth: ['500px','1100px'],
        margin: '0 auto',
        flexDirection: ['column','row'],
        alignItems: 'stretch'
        
      }}
    >
      <StyledBox bgImage sx={{
        flex: ['1 1 350px', '1 0'],
        alignSelf: 'stretch',
        width: ['100%', 'auto'],
        maxWidth: ['none','500px'],
        borderRadius: '50px',
        borderBottomRightRadius: [0, '50px'],
        borderBottomLeftRadius: [0, '50px'],
        overflow: 'hidden',
        zIndex: 1,
      }}>
        {/* <Img fluid={fluidImg} alt="test"/> */}
      </StyledBox>
      <Flex sx={{
        flex: '1 2',
        flexDirection: 'column',
      }}>
        <Flex sx={{
          marginLeft: '-80px', 
          transform: 'skew(-8deg)', 
          flexDirection: 'column',

          '@media screen and (max-width: 768px)': {
            transform: 'skew(0deg)',
            marginLeft: 0
          }
        
        }}>
          <Box sx={{ 
            backgroundColor: '#1C75BC',
            paddingY: '30px',
            paddingLeft: ['16px','80px'],
            paddingRight: '16px',
            borderTopRightRadius: [0,'80px'],
            borderTopLeftRadius: [0,'40px', 0]
          }}>
            <Styled.h2 sx={{
              margin: 0, 
              textTransform: 'uppercase', 
              transform: 'skew(8deg)',

              '@media screen and (max-width: 768px)': {
                transform: 'skew(0deg)'
              }
            }}>
              {title}
            </Styled.h2>
          </Box>
          <Flex sx={{
            backgroundColor: 'black',
            borderBottomRightRadius: '40px',
            borderBottomLeftRadius: ['40px','40px', 0],
            paddingLeft: ['16px','110px'],
            paddingRight: ['16px','30px'],
            paddingY: 8,
          }}>
            <Flex sx={{
              flexDirection: 'column', 
              transform: 'skew(8deg)', 
              justifyContent: 'space-between', 
              height: '100%',

              '@media screen and (max-width: 768px)': {
                transform: 'skew(0deg)'
              }
            
            }}>
              <Styled.p>{text}</Styled.p>

              { to && 
                <Button text="Learn More" to={to} sx={{marginTop: '60px', maxWidth: '250px'}} />
              }

              { !to &&
                <Box py={[2, 10]}></Box>
              }

            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </Container>
 
)

export default ShapedCard;